import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Features from "../components/Features";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Masonry from "react-masonry-css";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

// eslint-disable-next-line
const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};
const ModellingPage = ({ data }) => {
  const { edges: photoshoots } = data.allMarkdownRemark;

  return (
    <Layout>
       <section className="section modelling">
        <Helmet title={`Itza | Modelling`} />
        <div className="container content">
          <div className="columns">
            <div className="column">
              <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column">
              {photoshoots.map(({node:shoot}) => (
                <div>
                  <Link to={shoot.fields.slug}>
                    <PreviewCompatibleImage imageInfo={shoot.frontmatter.Photoshoot.photos[0]} />
                  </Link>
                </div>
                // <PhotoStack link={shoot.fields.slug} title={shoot.frontmatter.title} description={shoot.frontmatter.description} key={shoot.fields.slug} photos={shoot.frontmatter.Photoshoot.photos} mainPhoto={shoot.frontmatter.Photoshoot.photos[0]}/>
              ))}
              </Masonry>
            </div>
          </div>
        </div>
      </section>
      {/* <Features photoshoots={photoshoots}/> */}
    </Layout>
  );
};

ModellingPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    }),
  }),
};

export default ModellingPage;

export const pageQuery = graphql`
query MyQueryMODEL {
  allMarkdownRemark(
    filter: {frontmatter: {Photoshoot: {}, tags: {eq: "Modelling"}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          Photoshoot {
            photos {
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
